
















import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile",
      isInIOS: "isInIOS"
    }),
    ...mapState("assets", {
      images: "images"
    })
  },
  methods: {
    download(platform: "android" | "ios") {
      if (platform === "android" && this.isInIOS) {
        this.$message.info("" + this.$t("cyberbladeApp.downloadOnAndroid"));
        return;
      }
      dispatch.diyGetCyberbladeApp(platform).then(res => {
        switch (res.data.download_type) {
          case "file":
            download(res.data.url);
            break;
          case "redirect":
            window.open(res.data.download_content, "_blank");
            break;
          case "content":
            if (platform === "ios") {
              window.location.href =
                "https://apps.apple.com/app/id" + res.data.download_content;
            }
            break;
        }
      });
    }
  }
});
